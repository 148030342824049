@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap");

.mainLogin-div {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-color: #fff;
}

.mainLogin-left {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f2f2eb;
}

.leftInner-login {
  width: 600px;
  height: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leftInner-login > div:first-child {
  color: #444;
  font-size: 15px;
  letter-spacing: 4px;
  font-weight: bolder;
  font-family: "Work Sans", sans-serif;
}

.leftInner-login > div:nth-child(2) {
  text-align: center;
  font-size: 25px;
  /* text-transform: uppercase; */
  font-weight: bold;
  color: #df3535;
  font-family: "Work Sans", sans-serif;
  /* letter-spacing: 2px; */
}

.leftInner-login > div:nth-child(3) {
  font-size: 13px;
  color: #444;
  letter-spacing: 1px;
}

.leftInner-login > div:nth-child(4) {
  margin: 20px;
  width: 45%
}

.leftInner-login > div:nth-child(4) > * {
  margin-top: 30px;
}

.leftInner-login > div:nth-child(5) {
  font-size: 14px;
}

.leftInner-login > div:nth-child(6) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
}

.leftInner-login > div:nth-child(6) > div:nth-child(1),
.leftInner-login > div:nth-child(6) > div:nth-child(2) {
  width: 150px;
  height: 1px;
  margin: 40px 10px;
  background-color: #ccc;
}

.leftInner-login > div:nth-child(7) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftInner-login > div:nth-child(7) > span {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: #f1f1f1;
  cursor: pointer;
  border-radius: 50%;
  border: 0.5px solid #1c5272;
  margin: 0 20px;
  box-shadow: 1px 2px 3px #555;
}

.leftInner-login > div:nth-child(7) > span:hover {
  background-color: #ccc;
}

.leftInner-login > div:nth-child(7) > span > img {
  width: 50%;
}

.resendDiv {
  margin-top: 10px;
}

.resendDiv > span {
  color: #1c5272;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 14px;
}

.mainLogin-right {
  width: 52%;
  height: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  flex-direction: column;

  padding: 100px;
}

.mainLogin-right::before {
  content: "";
  background: linear-gradient(
      /* rgba(255, 255, 255, 0.4), */ rgba(199, 84, 67, 0.9),
      rgba(199, 84, 67, 0.2) /* rgba(255, 255, 255, 0.5) */
    ),
    url("https://images.unsplash.com/photo-1618326889227-8cf3c304ced8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=871&q=80");

  background-size: 100% 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;

  /* opacity: 0.75; */
}

.mainLogin-right > div:nth-child(2) {
  margin: 20px 0;
  font-size: 30px;
  letter-spacing: 2px;
  text-shadow: 6px 6px 0px rgba(50, 50, 50, 0.4);
  font-weight: bold;
  color: #f1f1f1;
  font-family: "Work Sans", sans-serif;
}

.mainLogin-right > div:nth-child(3) {
  width: 200px;
  height: 3px;
  background-color: #1c5272;
  margin-bottom: 20px;
}

.mainLogin-right > div:nth-child(4) {
  font-size: 16px;
  color: #ddd;
  text-align: center;
  /* background-color: red; */
  font-family: "Work Sans", sans-serif;
}

.btn {
  height: 40px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 20px;
  background-color: #1c5272;
  color: #fff;
  letter-spacing: 1px;
  font-weight: bold;
  /* box-shadow: 2px 3px 5px #555; */
}

.btn:hover {
  background-color: #3a5361;
  cursor: pointer;
}

.btn:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.regHere {
  color: #1c5272;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .leftInner-login > div:nth-child(4) {
    margin: 20px;
    width: 100%
  }
}

.ant-drawer-body {
  display: flex;
}

.changepwdiv {
  display: flex;
  width: 100%;
}