.trigger {
  /* padding: 0 24px; */
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  /* background: rgba(12, 12, 12, 0.815); */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 3px 4px #555;
  font-family: "Satisfy", cursive;
  /* transition: 1s; */
}

.homeLogo-img {
  width: 25%;
  height: 25%;
  transition: 0.5s ease-out;
}

.logoOpen {
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  background: rgba(12, 12, 12, 0.815);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 3px 4px #555;
  font-family: "Satisfy", cursive;
  transition: 0.5s !important;
}

.logoOpen > img {
  width: 40%;
  height: 25px;
  /* transition: 1s; */
}

.site-layout{
  
}

.site-layout .site-layout-background {
  background: #fff;
  overflow: auto !important;
  padding-right: 15px;
}

.headerHome {
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px !important;
}

.homeProfile-img {
  width: 45px;
  height: 80%;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #df3535;
  padding: 1px;
}

.profileContent {
  display: flex;
  flex-direction: column;
  font-size: 14.5px;
  /* background-color: red; */
  /* jus */
}
.profileContent > div {
  width: 100%;
  padding-right: 20px;
  margin-bottom: 5px;
  letter-spacing: 0.8px;
  color: #333;
  font-weight: bold;
  /* background-color: red; */
  cursor: pointer;
}

.profileContent > div:hover {
  background-color: #f1f1f1;
}

/* (RYE) changes to the dashboard */
.mainDash-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mainDash-div > * {
  margin-right: 20px;
  margin-bottom: 40px !important;
}

.cardDash {
  width: 290px;
  height: auto;
  background-color: #d93939;
  border-radius: 10px;
  color: #fff;
}

.cardDash:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: default;
}

.cardHead {
  padding: 10px 20px;
  height: 65px;
  text-align: center;
  letter-spacing: 0.8px;
  /* font-size: 14px; */
  font-weight: bold;
  border-bottom: 0.6px ridge #c9898e;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

.cardBody {
  padding: 10px 20px;
  letter-spacing: 0.8px;
  font-size: 15px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: orange; */
}

.cardDash > div:nth-child(4) {
  /* height: 40px; */
  /* background-color: red; */
  padding: 10px 20px;
  display: flex;
  justify-content: center;
}

.imgIcon {
  width: 100px;
  height: 100px;
}
/* (rye) fontawesome */
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%; 
  width: 70%; 
  background-color: #d2e1eb;
  /* border: 1px solid #b9c5ce; */

  
}
/* (rye) fontawesome */
.responsiveIcon {
  width: 90%;
  height: 90%;
}

.dashCard {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: #d2e1eb;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  margin: 20px;
}

.dashCard:hover {
  box-shadow: rgba(50, 50, 93, 0.3) 0px 100px 200px -20px,
    rgba(0, 0, 0, 0.3) 0px 60px 60px -30px;
}

/* (rye) modification to the dashboard */
/* .dashCard img {
  width: 150px;
  height: 125px;
  border: 1px solid black;
  padding: 0.2em;
  background-color: aliceblue;
} */

.cardIcon {
  width: 150px;
  height: 100px;
}

.dashCard > div:first-child {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.7px;
  color: #222;
  padding-bottom: 10px;
  /* border-bottom: 1px solid #64a5ca; */
}

.dashCard-bottom {
  height: 30%;
  /* background-color: red; */
}

.hidden{
  display: none
}
.flexDash {
  display: flex;
  flex-wrap: wrap;

}
/* (RYE) Adjust the layout */
.mainDashboard {
  flex: 1 0 20%; /* (RYE) This means that each card will take up 20% of the row (100% / 5) */
  max-width: 20%; /* (RYE) This is to prevent the card from taking up more space if there are less than 5 cards in a row */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.my-dropdown-menu {
  width: 120px; /* Adjust this value to your liking */
}

.card {
  transition: all 0.3s ease;
}
.card:hover {
  border-image: linear-gradient(to right, #add8e6, #87cefa);
  background: linear-gradient(to right, #add8e6, #87cefa);  
  border-radius: 10px;
}

.menu {
  display: none;  /* hide on larger screens */
}

.ant-dropdown-link {
  margin-right: 30px;
}

.logo_title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-left: 5px;

}

@media only screen and (max-width: 430px) {
  .cardBody {
    height: auto;
  }
  .cardHead {
    height: auto;
  }
  
  .card {
    font-size: 12px;
  }

  .homeLogo-img {
    display: none;
  }

  .menu {
    display: block;  /* hide on larger screens */
  }

  .ant-dropdown-link {
    margin-right: 0px;
  }
  .logo_title {
    display: none;
  }
  .username{
    display: none;
  }
  .logoFor {
    width: 50% !important;
    height: 50% !important;
  }
}






/* (RYE) Adjust the layout for smaller screens */
@media (max-width: 1200px) {
  .mainDashboard {
    flex: 1 0 25%; /* (RYE) 4 cards per row */
    max-width: 25%;
  }
}

@media (max-width: 900px) {
  .mainDashboard {
    flex: 1 0 33.33%; /* (RYE) 3 cards per row */
    max-width: 33.33%;
  }
}


@media (max-width: 600px) {
  .mainDashboard {
    flex: 1 0 100%; /* (RYE) 1 card per row */
    max-width: 100%;
  }
  .homeLogo-img{
    display: none;
  }

  .menu {
    display: block;  
    margin-top: -10px;
  }
  .logo_title {
    display: none;
  }

  .logoFor {
    width: 50% !important;
    height: 50% !important;
  }
}