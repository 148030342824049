@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&display=swap");

.mainRegister-div {
  width: 100%;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 900px;
}

.mainRegister-div::before {
  content: "";
  background: linear-gradient(rgba(36, 41, 43, 0.5), rgba(30, 35, 37, 0.4)), url("../../images/bg2.jpg");

  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.mainHeader-login {
  height: 40px;
  position: fixed;
  top: 0px;
  width: 98.5vw;
  z-index: 99;
  background-color: #1c5272;
  display: flex;
  justify-content: space-between;
}

.registerCard-div {
  width: 700px;
  min-height: 500px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 24%), 0 6px 16px 0 rgb(0 0 0 / 16%),
    0 9px 28px 8px rgb(0 0 0 / 10%);
  display: flex;
  overflow: hidden;
}

.loginHead-title {
  margin-left: 45px;
  height: 100%;
  display: flex;
  align-items: center;
}

.loginHead-title > img {
  width: 40px;
  height: 30px;
}

.loginHead-title > span {
  font-family: "Darker Grotesque", sans-serif;
  color: #f1f1f1;
  font-size: 17px;
  letter-spacing: 0.8px;
  margin-left: 15px;
}

.loginCard-left,
.loginCard-right {
  width: 50%;
}

.loginCard-right {
  background-color: rgba(28, 82, 114, 1);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loginCard-right > div:nth-child(2) {
  width: 70px;
  height: 3px;
  background-color: #f1f1f1;
  margin: 30px 0 20px 0;
}

.loginCard-right > span {
  /* flex-direction: column; */
  /* margin-top: 25px; */
  /* background-color: red; */
  padding: 0 50px;
  letter-spacing: 2px;
  font-size: 30px;
  word-spacing: 10px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  font-family: "Darker Grotesque", sans-serif;
}

.loginCard-left {
  /* padding: 0px; */
  display: flex;
  padding: 30px 0;
  /* justify-content: space-around; */
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.loginInput {
  width: 100% !important;
  /* margin-bottom: 20px !important; */
}

.loginCard-left > span {
  width: 80%;
  margin-top: 10px;
}

.loginInput-label {
  color: #555;
  margin-left: 5px;
  font-size: 13px;
  /* margin-bottom: 10px !important; */
  letter-spacing: 0.8px;
  font-weight: bold;
}

.loginInput-label-no-bold {
  color: #555;
  margin-left: 5px;
  font-size: 13px;
  /* margin-bottom: 10px !important; */
  letter-spacing: 0.8px;
}

.btns {
  background-color: #1c5272;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 5.3px 20px;
}

.btns:hover,
.btns:focus {
  background-color: #0d364e;
  cursor: pointer;
}

.btns:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.btnLogin {
  width: 100%;
  margin-top: 10px;
}

.forgotPwd > span {
  /* font-weight: bold; */
  margin-left: 7px;
  color: #4691ff;
  cursor: pointer;
}

.loginHead-date {
  /* background-color: red; */
  margin-right: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Darker Grotesque", sans-serif;
  color: #f1f1f1;
  font-size: 15.5px;
  letter-spacing: 0.8px;
}

.loginTitle {
  font-family: "Acme", sans-serif;
  font-size: 25px;
  font-weight: bolder;
  color: #1c5272;
  letter-spacing: 5px;
  /* background-color: red; */
}

.regFoot {
  width: 80%;
  font-size: 13px;
  margin-top: 16px;
  /* background-color: red; */
}

.regFoot > span:nth-child(2) {
  color: #4691ff;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
}

.reg3-row {
  margin-bottom: 10px;
  width: 80%;
  display: inline-flex;
  justify-content: space-between;
}

.reg3-row > span {
  width: 32%;
}

.reg2-row {
  margin-bottom: 10px;
  width: 80%;
  display: inline-flex;
  justify-content: space-between;
}

.reg2-row > span {
  width: 49%;
}

.dataPrivacy {
  color: #4691ff;
  cursor: pointer;
  font-size: 14px;
}

.socialOR {
  padding: 10px 0;
  color: #444;
  font-size: 12.5px;
  letter-spacing: 1px;
  /* background-color: red; */
  width: 80%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.socialOR > div {
  width: 70px;
  height: 1px;
  margin: 0 10px;
  background-color: #999;
}

.socialBtn {
  /* background-color: red; */
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnSocial {
  /* padding: 10px; */
  border-radius: 50%;
  width: 35px;
  cursor: pointer;
  margin: 0 10px;
  /* outline: none;
  border: none;
  background-color: #3b5998; */
}

.btnSocial:hover {
  opacity: 0.8;
}

.btnSocial > img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .loginCard-div {
    width: 95%;
  }
}
@media only screen and (max-width: 580px) {
  .loginCard-left > span {
    width: 90%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .loginCard-div {
    flex-direction: column;
    width: 80%;
    height: auto;
  }

  .loginCard-left,
  .loginCard-right {
    width: 100%;
  }

  .loginCard-right {
    height: 300px;
  }

  .loginHead-title {
    margin-left: 10px;
  }

  .loginHead-title > span {
    font-size: 14px;
  }

  .loginHead-date {
    font-size: 13px !important;
  }

  .loginCard-right > span {
    font-size: 20px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .loginHead-date {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .loginHead-title > span {
    display: none;
  }

  .loginCard-div {
    width: 90%;
  }
}
