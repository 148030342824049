@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trigger {
  /* padding: 0 24px; */
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  /* background: rgba(12, 12, 12, 0.815); */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 3px 4px #555;
  font-family: "Satisfy", cursive;
  /* transition: 1s; */
}

.homeLogo-img {
  width: 25%;
  height: 25%;
  transition: 0.5s ease-out;
}

.logoOpen {
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  background: rgba(12, 12, 12, 0.815);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 3px 4px #555;
  font-family: "Satisfy", cursive;
  transition: 0.5s !important;
}

.logoOpen > img {
  width: 40%;
  height: 25px;
  /* transition: 1s; */
}

.site-layout{
  
}

.site-layout .site-layout-background {
  background: #fff;
  overflow: auto !important;
  padding-right: 15px;
}

.headerHome {
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px !important;
}

.homeProfile-img {
  width: 45px;
  height: 80%;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #df3535;
  padding: 1px;
}

.profileContent {
  display: flex;
  flex-direction: column;
  font-size: 14.5px;
  /* background-color: red; */
  /* jus */
}
.profileContent > div {
  width: 100%;
  padding-right: 20px;
  margin-bottom: 5px;
  letter-spacing: 0.8px;
  color: #333;
  font-weight: bold;
  /* background-color: red; */
  cursor: pointer;
}

.profileContent > div:hover {
  background-color: #f1f1f1;
}

/* (RYE) changes to the dashboard */
.mainDash-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mainDash-div > * {
  margin-right: 20px;
  margin-bottom: 40px !important;
}

.cardDash {
  width: 290px;
  height: auto;
  background-color: #d93939;
  border-radius: 10px;
  color: #fff;
}

.cardDash:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: default;
}

.cardHead {
  padding: 10px 20px;
  height: 65px;
  text-align: center;
  letter-spacing: 0.8px;
  /* font-size: 14px; */
  font-weight: bold;
  border-bottom: 0.6px ridge #c9898e;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}

.cardBody {
  padding: 10px 20px;
  letter-spacing: 0.8px;
  font-size: 15px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: orange; */
}

.cardDash > div:nth-child(4) {
  /* height: 40px; */
  /* background-color: red; */
  padding: 10px 20px;
  display: flex;
  justify-content: center;
}

.imgIcon {
  width: 100px;
  height: 100px;
}
/* (rye) fontawesome */
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%; 
  width: 70%; 
  background-color: #d2e1eb;
  /* border: 1px solid #b9c5ce; */

  
}
/* (rye) fontawesome */
.responsiveIcon {
  width: 90%;
  height: 90%;
}

.dashCard {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  background-color: #d2e1eb;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  margin: 20px;
}

.dashCard:hover {
  box-shadow: rgba(50, 50, 93, 0.3) 0px 100px 200px -20px,
    rgba(0, 0, 0, 0.3) 0px 60px 60px -30px;
}

/* (rye) modification to the dashboard */
/* .dashCard img {
  width: 150px;
  height: 125px;
  border: 1px solid black;
  padding: 0.2em;
  background-color: aliceblue;
} */

.cardIcon {
  width: 150px;
  height: 100px;
}

.dashCard > div:first-child {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.7px;
  color: #222;
  padding-bottom: 10px;
  /* border-bottom: 1px solid #64a5ca; */
}

.dashCard-bottom {
  height: 30%;
  /* background-color: red; */
}

.hidden{
  display: none
}
.flexDash {
  display: flex;
  flex-wrap: wrap;

}
/* (RYE) Adjust the layout */
.mainDashboard {
  flex: 1 0 20%; /* (RYE) This means that each card will take up 20% of the row (100% / 5) */
  max-width: 20%; /* (RYE) This is to prevent the card from taking up more space if there are less than 5 cards in a row */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.my-dropdown-menu {
  width: 120px; /* Adjust this value to your liking */
}

.card {
  transition: all 0.3s ease;
}
.card:hover {
  border-image: linear-gradient(to right, #add8e6, #87cefa);
  background: linear-gradient(to right, #add8e6, #87cefa);  
  border-radius: 10px;
}

.menu {
  display: none;  /* hide on larger screens */
}

.ant-dropdown-link {
  margin-right: 30px;
}

.logo_title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-left: 5px;

}

@media only screen and (max-width: 430px) {
  .cardBody {
    height: auto;
  }
  .cardHead {
    height: auto;
  }
  
  .card {
    font-size: 12px;
  }

  .homeLogo-img {
    display: none;
  }

  .menu {
    display: block;  /* hide on larger screens */
  }

  .ant-dropdown-link {
    margin-right: 0px;
  }
  .logo_title {
    display: none;
  }
  .username{
    display: none;
  }
  .logoFor {
    width: 50% !important;
    height: 50% !important;
  }
}






/* (RYE) Adjust the layout for smaller screens */
@media (max-width: 1200px) {
  .mainDashboard {
    flex: 1 0 25%; /* (RYE) 4 cards per row */
    max-width: 25%;
  }
}

@media (max-width: 900px) {
  .mainDashboard {
    flex: 1 0 33.33%; /* (RYE) 3 cards per row */
    max-width: 33.33%;
  }
}


@media (max-width: 600px) {
  .mainDashboard {
    flex: 1 0 100%; /* (RYE) 1 card per row */
    max-width: 100%;
  }
  .homeLogo-img{
    display: none;
  }

  .menu {
    display: block;  
    margin-top: -10px;
  }
  .logo_title {
    display: none;
  }

  .logoFor {
    width: 50% !important;
    height: 50% !important;
  }
}
.mainReg-div {
  width: 100%;
  /* height: 100vh; */
  /* min-height: 500px; */
  max-height: auto;
  display: flex;
  justify-content: center;
  /* padding: 50px 200px; */
}

.regDiv-inner {
  /* background-color: #f1f1f1; */
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  height: auto;
  width: 100%;
  padding: 20px 50px;
  margin-top: 20px;
}

.registerMain-div {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 20px;
  /* background-color: ; */
}

.registerMain-div > * {
  margin-bottom: 20px;
}

.editUserMain-div > * {
  margin-bottom: 20px;
}

.row1 {
  width: 100%;
}

.row1 > * {
  width: 100%;
}

.row2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.row2 > * {
  width: 49%;
}

.row3 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.row3 > * {
  width: 32%;
}

.regWarning {
  font-size: 13px;
  color: red;
  letter-spacing: 0.7px;
}

.verBtn {
  color: #1c5272;
  cursor: pointer;
}

.verBtn:hover {
  text-decoration: underline;
}

.tblHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  /* background-color: red; */
}

.custom-search .ant-input-affix-wrapper {
  border: 2px solid #d9d9d9;
}

.custom-search .ant-input-affix-wrapper .ant-input {
  border-radius: 0;
  box-shadow: none;
  padding: 0 11px;
}

.custom-search .ant-input-search-button {
  background-color: #fff; /* Change as needed */
  border: 2px solid #d9d9d9;
  box-shadow: none;
  height: 33.3px;
  margin-right: 2px;
}


.custom-search .ant-input-search-button:focus {
  outline: none; /* Removes the default browser outline */
}

.custom-search .ant-input-search-button svg {
  fill: #959595; /* Change as needed */
}

.custom-table .ant-table {
  border: 1px solid #d9d9d9;

}

.custom-table .ant-table-thead > tr > th {
  background-color: #fff;
  border-color:#d9d9d9 !important;
  color: #6d6d6d;
  font-weight: bolder;
  border-width: 2px; /* Change as needed */
  height: 45px;
}

.custom-table .ant-table-tbody > tr > td {
  border-color: #d9d9d9 !important; /* Change as needed */
  border-width: 2px
}


@media (max-width: 450px) {
  .tblHead {
     display: flex;
     flex-direction: column;
     text-align: center;
  }
}

@media (max-width: 650px) {
  .tblHead {
     display: flex;
     flex-direction: column;
     text-align: center;
  }
}

@media (max-width: 750px) {
  .tblHead {
     display: flex;
     flex-direction: column;
     text-align: center;
  }
}

.mainLogin-div {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  background-color: #fff;
}

.mainLogin-left {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f2f2eb;
}

.leftInner-login {
  width: 600px;
  height: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leftInner-login > div:first-child {
  color: #444;
  font-size: 15px;
  letter-spacing: 4px;
  font-weight: bolder;
  font-family: "Work Sans", sans-serif;
}

.leftInner-login > div:nth-child(2) {
  text-align: center;
  font-size: 25px;
  /* text-transform: uppercase; */
  font-weight: bold;
  color: #df3535;
  font-family: "Work Sans", sans-serif;
  /* letter-spacing: 2px; */
}

.leftInner-login > div:nth-child(3) {
  font-size: 13px;
  color: #444;
  letter-spacing: 1px;
}

.leftInner-login > div:nth-child(4) {
  margin: 20px;
  width: 45%
}

.leftInner-login > div:nth-child(4) > * {
  margin-top: 30px;
}

.leftInner-login > div:nth-child(5) {
  font-size: 14px;
}

.leftInner-login > div:nth-child(6) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
}

.leftInner-login > div:nth-child(6) > div:nth-child(1),
.leftInner-login > div:nth-child(6) > div:nth-child(2) {
  width: 150px;
  height: 1px;
  margin: 40px 10px;
  background-color: #ccc;
}

.leftInner-login > div:nth-child(7) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftInner-login > div:nth-child(7) > span {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: #f1f1f1;
  cursor: pointer;
  border-radius: 50%;
  border: 0.5px solid #1c5272;
  margin: 0 20px;
  box-shadow: 1px 2px 3px #555;
}

.leftInner-login > div:nth-child(7) > span:hover {
  background-color: #ccc;
}

.leftInner-login > div:nth-child(7) > span > img {
  width: 50%;
}

.resendDiv {
  margin-top: 10px;
}

.resendDiv > span {
  color: #1c5272;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 14px;
}

.mainLogin-right {
  width: 52%;
  height: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  flex-direction: column;

  padding: 100px;
}

.mainLogin-right::before {
  content: "";
  background: linear-gradient(
      /* rgba(255, 255, 255, 0.4), */ rgba(199, 84, 67, 0.9),
      rgba(199, 84, 67, 0.2) /* rgba(255, 255, 255, 0.5) */
    ),
    url("https://images.unsplash.com/photo-1618326889227-8cf3c304ced8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=871&q=80");

  background-size: 100% 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;

  /* opacity: 0.75; */
}

.mainLogin-right > div:nth-child(2) {
  margin: 20px 0;
  font-size: 30px;
  letter-spacing: 2px;
  text-shadow: 6px 6px 0px rgba(50, 50, 50, 0.4);
  font-weight: bold;
  color: #f1f1f1;
  font-family: "Work Sans", sans-serif;
}

.mainLogin-right > div:nth-child(3) {
  width: 200px;
  height: 3px;
  background-color: #1c5272;
  margin-bottom: 20px;
}

.mainLogin-right > div:nth-child(4) {
  font-size: 16px;
  color: #ddd;
  text-align: center;
  /* background-color: red; */
  font-family: "Work Sans", sans-serif;
}

.btn {
  height: 40px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 20px;
  background-color: #1c5272;
  color: #fff;
  letter-spacing: 1px;
  font-weight: bold;
  /* box-shadow: 2px 3px 5px #555; */
}

.btn:hover {
  background-color: #3a5361;
  cursor: pointer;
}

.btn:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.regHere {
  color: #1c5272;
  font-weight: bold;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .leftInner-login > div:nth-child(4) {
    margin: 20px;
    width: 100%
  }
}

.ant-drawer-body {
  display: flex;
}

.changepwdiv {
  display: flex;
  width: 100%;
}
.mainRegister-div {
  width: 100%;
  padding-bottom: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  height: 900px;
}

.mainRegister-div::before {
  content: "";
  background: linear-gradient(rgba(36, 41, 43, 0.5), rgba(30, 35, 37, 0.4)), url(/static/media/bg2.63f3dda3.jpg);

  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

.mainHeader-login {
  height: 40px;
  position: fixed;
  top: 0px;
  width: 98.5vw;
  z-index: 99;
  background-color: #1c5272;
  display: flex;
  justify-content: space-between;
}

.registerCard-div {
  width: 700px;
  min-height: 500px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 24%), 0 6px 16px 0 rgb(0 0 0 / 16%),
    0 9px 28px 8px rgb(0 0 0 / 10%);
  display: flex;
  overflow: hidden;
}

.loginHead-title {
  margin-left: 45px;
  height: 100%;
  display: flex;
  align-items: center;
}

.loginHead-title > img {
  width: 40px;
  height: 30px;
}

.loginHead-title > span {
  font-family: "Darker Grotesque", sans-serif;
  color: #f1f1f1;
  font-size: 17px;
  letter-spacing: 0.8px;
  margin-left: 15px;
}

.loginCard-left,
.loginCard-right {
  width: 50%;
}

.loginCard-right {
  background-color: rgba(28, 82, 114, 1);
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loginCard-right > div:nth-child(2) {
  width: 70px;
  height: 3px;
  background-color: #f1f1f1;
  margin: 30px 0 20px 0;
}

.loginCard-right > span {
  /* flex-direction: column; */
  /* margin-top: 25px; */
  /* background-color: red; */
  padding: 0 50px;
  letter-spacing: 2px;
  font-size: 30px;
  word-spacing: 10px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  font-family: "Darker Grotesque", sans-serif;
}

.loginCard-left {
  /* padding: 0px; */
  display: flex;
  padding: 30px 0;
  /* justify-content: space-around; */
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.loginInput {
  width: 100% !important;
  /* margin-bottom: 20px !important; */
}

.loginCard-left > span {
  width: 80%;
  margin-top: 10px;
}

.loginInput-label {
  color: #555;
  margin-left: 5px;
  font-size: 13px;
  /* margin-bottom: 10px !important; */
  letter-spacing: 0.8px;
  font-weight: bold;
}

.loginInput-label-no-bold {
  color: #555;
  margin-left: 5px;
  font-size: 13px;
  /* margin-bottom: 10px !important; */
  letter-spacing: 0.8px;
}

.btns {
  background-color: #1c5272;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 5.3px 20px;
}

.btns:hover,
.btns:focus {
  background-color: #0d364e;
  cursor: pointer;
}

.btns:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.btnLogin {
  width: 100%;
  margin-top: 10px;
}

.forgotPwd > span {
  /* font-weight: bold; */
  margin-left: 7px;
  color: #4691ff;
  cursor: pointer;
}

.loginHead-date {
  /* background-color: red; */
  margin-right: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Darker Grotesque", sans-serif;
  color: #f1f1f1;
  font-size: 15.5px;
  letter-spacing: 0.8px;
}

.loginTitle {
  font-family: "Acme", sans-serif;
  font-size: 25px;
  font-weight: bolder;
  color: #1c5272;
  letter-spacing: 5px;
  /* background-color: red; */
}

.regFoot {
  width: 80%;
  font-size: 13px;
  margin-top: 16px;
  /* background-color: red; */
}

.regFoot > span:nth-child(2) {
  color: #4691ff;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
}

.reg3-row {
  margin-bottom: 10px;
  width: 80%;
  display: inline-flex;
  justify-content: space-between;
}

.reg3-row > span {
  width: 32%;
}

.reg2-row {
  margin-bottom: 10px;
  width: 80%;
  display: inline-flex;
  justify-content: space-between;
}

.reg2-row > span {
  width: 49%;
}

.dataPrivacy {
  color: #4691ff;
  cursor: pointer;
  font-size: 14px;
}

.socialOR {
  padding: 10px 0;
  color: #444;
  font-size: 12.5px;
  letter-spacing: 1px;
  /* background-color: red; */
  width: 80%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.socialOR > div {
  width: 70px;
  height: 1px;
  margin: 0 10px;
  background-color: #999;
}

.socialBtn {
  /* background-color: red; */
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnSocial {
  /* padding: 10px; */
  border-radius: 50%;
  width: 35px;
  cursor: pointer;
  margin: 0 10px;
  /* outline: none;
  border: none;
  background-color: #3b5998; */
}

.btnSocial:hover {
  opacity: 0.8;
}

.btnSocial > img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .loginCard-div {
    width: 95%;
  }
}
@media only screen and (max-width: 580px) {
  .loginCard-left > span {
    width: 90%;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .loginCard-div {
    flex-direction: column;
    width: 80%;
    height: auto;
  }

  .loginCard-left,
  .loginCard-right {
    width: 100%;
  }

  .loginCard-right {
    height: 300px;
  }

  .loginHead-title {
    margin-left: 10px;
  }

  .loginHead-title > span {
    font-size: 14px;
  }

  .loginHead-date {
    font-size: 13px !important;
  }

  .loginCard-right > span {
    font-size: 20px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .loginHead-date {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .loginHead-title > span {
    display: none;
  }

  .loginCard-div {
    width: 90%;
  }
}

