.mainReg-div {
  width: 100%;
  /* height: 100vh; */
  /* min-height: 500px; */
  max-height: auto;
  display: flex;
  justify-content: center;
  /* padding: 50px 200px; */
}

.regDiv-inner {
  /* background-color: #f1f1f1; */
  /* border: 1px solid #ddd; */
  border-radius: 10px;
  height: auto;
  width: 100%;
  padding: 20px 50px;
  margin-top: 20px;
}

.registerMain-div {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 20px;
  /* background-color: ; */
}

.registerMain-div > * {
  margin-bottom: 20px;
}

.editUserMain-div > * {
  margin-bottom: 20px;
}

.row1 {
  width: 100%;
}

.row1 > * {
  width: 100%;
}

.row2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.row2 > * {
  width: 49%;
}

.row3 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.row3 > * {
  width: 32%;
}

.regWarning {
  font-size: 13px;
  color: red;
  letter-spacing: 0.7px;
}

.verBtn {
  color: #1c5272;
  cursor: pointer;
}

.verBtn:hover {
  text-decoration: underline;
}

.tblHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  /* background-color: red; */
}

.custom-search .ant-input-affix-wrapper {
  border: 2px solid #d9d9d9;
}

.custom-search .ant-input-affix-wrapper .ant-input {
  border-radius: 0;
  box-shadow: none;
  padding: 0 11px;
}

.custom-search .ant-input-search-button {
  background-color: #fff; /* Change as needed */
  border: 2px solid #d9d9d9;
  box-shadow: none;
  height: 33.3px;
  margin-right: 2px;
}


.custom-search .ant-input-search-button:focus {
  outline: none; /* Removes the default browser outline */
}

.custom-search .ant-input-search-button svg {
  fill: #959595; /* Change as needed */
}

.custom-table .ant-table {
  border: 1px solid #d9d9d9;

}

.custom-table .ant-table-thead > tr > th {
  background-color: #fff;
  border-color:#d9d9d9 !important;
  color: #6d6d6d;
  font-weight: bolder;
  border-width: 2px; /* Change as needed */
  height: 45px;
}

.custom-table .ant-table-tbody > tr > td {
  border-color: #d9d9d9 !important; /* Change as needed */
  border-width: 2px
}


@media (max-width: 450px) {
  .tblHead {
     display: flex;
     flex-direction: column;
     text-align: center;
  }
}

@media (max-width: 650px) {
  .tblHead {
     display: flex;
     flex-direction: column;
     text-align: center;
  }
}

@media (max-width: 750px) {
  .tblHead {
     display: flex;
     flex-direction: column;
     text-align: center;
  }
}